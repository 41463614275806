<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-16 14:35:56
 * @ Description: Fixer's services/talent dashboard on the landing page.
 -->

<template>
    <v-row
        :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 200px);' : $vuetify.breakpoint.sm ? 'max-height: calc(100vh - 150px);' : 'max-height: calc(100vh - 83px); max-width: 100vw; position: absolute; top: 71px;'"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.smAndUp ? 'position: relative; top: 80px;' : ''"
        >
            <!-- Filters -->
            <v-row
                align="center"
                v-if="$vuetify.breakpoint.smAndUp"
            >
                <v-col
                    class="flex-shrink-1 flex-grow-0 os-15-sb"
                    v-if="$vuetify.breakpoint.mdAndUp"
                >
                    Filter:
                </v-col>
                <v-col
                    class="flex-grow-1 flex-shrink-0"
                >
                    <v-row>
                        <v-col
                            :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                        >
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="os-13-r"
                                background-color="white"
                                placeholder="Industry"
                                hide-details
                                :items="industries"
                                return-object
                                item-text="name"
                                item-value="id"
                                v-model="searchIndustry"
                                clearable
                                @click:clear="searchIndustry = { id: null }"
                            />
                        </v-col>
                        <!-- <v-col
                            :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                        >
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="os-13-r"
                                background-color="white"
                                placeholder="Job Title"
                                hide-details
                                :items="jobTitles"
                                return-object
                                item-text="name"
                                item-value="id"
                                v-model="searchJobTitle"
                                clearable
                                @click:clear="searchJobTitle = { id: null }"
                            />
                        </v-col> -->
                        <!-- <v-col
                            :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                        >
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="os-13-r"
                                background-color="white"
                                placeholder="Job Mode"
                                hide-details
                                :items="jobModes"
                                return-object
                                item-text="name"
                                item-value="id"
                                v-model="searchJobMode"
                                clearable
                                @click:clear="clearJobMode"
                            />
                        </v-col> -->
                        <v-col
                            :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                        >
                            <general-google-autocomplete-address-component
                                @googlePlaceSelected="googlePlaceSelected"
                                @addressChanged="addressChanged"
                                :address="address"
                                :placeholder="locationPlaceholder"
                                :hideDetails="true"
                                :clearable="true"
                                :disabled="!canEnterLocation"
                                class="mt-n2"
                            />
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                v-model="mainSearch"
                                clearable
                                @click:clear="clearMainSearch"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    cols="12"
                    class="text-right pt-0"
                >
                    <v-btn
                        class="no-uppercase rounded-lg os-13-sb mr-1"
                        color="#2E567A"
                        text
                        @click="clearFilters"
                    >
                        Clear Filters
                    </v-btn>
                    <!-- Search Button -->
                    <v-btn
                        class="white--text no-uppercase rounded-lg os-13-sb"
                        color="#FFA858"
                        @click="filterTalent"
                    >
                        <span v-if="!$store.state.general.customBreakpoints.xxs">Filter Talent</span>
                        <v-icon
                            class="ml-1"
                        >
                            {{ mdiFilterPlusOutline }}
                        </v-icon>
                    </v-btn>
                    <!-- Search Button -->
                </v-col>
            </v-row>
            <!-- Filters -->

            <v-row
                :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 214px); overflow-y: auto;' : $vuetify.breakpoint.sm ? 'max-height: calc(100vh - 251px); overflow-y: auto;' : 'max-height: calc(100vh - 83px); overflow-y: auto;'"
                id="talentContainer"
            >
                <v-col
                    cols="12"
                >
                    <!-- Filters -->
                    <v-row
                        align="center"
                        v-if="$vuetify.breakpoint.xs"
                    >
                        <v-col
                            class="flex-grow-1 flex-shrink-0"
                        >
                            <v-row>
                                <v-col
                                    :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                                >
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="os-13-r"
                                        background-color="white"
                                        placeholder="Industry"
                                        hide-details
                                        :items="industries"
                                        return-object
                                        item-text="name"
                                        item-value="id"
                                        v-model="searchIndustry"
                                        clearable
                                        @click:clear="searchIndustry = { id: null }"
                                    />
                                </v-col>
                                <!-- <v-col
                                    :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                                >
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="os-13-r"
                                        background-color="white"
                                        placeholder="Job Title"
                                        hide-details
                                        :items="jobTitles"
                                        return-object
                                        item-text="name"
                                        item-value="id"
                                        v-model="searchJobTitle"
                                        clearable
                                        @click:clear="searchJobTitle = { id: null }"
                                    />
                                </v-col> -->
                                <!-- <v-col
                                    :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                                >
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="os-13-r"
                                        background-color="white"
                                        placeholder="Job Mode"
                                        hide-details
                                        :items="jobModes"
                                        return-object
                                        item-text="name"
                                        item-value="id"
                                        v-model="searchJobMode"
                                        clearable
                                        @click:clear="clearJobMode"
                                    />
                                </v-col> -->
                                <v-col
                                    :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                                >
                                    <general-google-autocomplete-address-component
                                        @googlePlaceSelected="googlePlaceSelected"
                                        @addressChanged="addressChanged"
                                        :address="address"
                                        :placeholder="locationPlaceholder"
                                        :hideDetails="true"
                                        :clearable="true"
                                        :disabled="!canEnterLocation"
                                        class="mt-n2"
                                    />
                                </v-col>
                                <v-col
                                    :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                                >
                                    <v-text-field
                                        dense
                                        hide-details
                                        outlined
                                        color="#2E567A"
                                        placeholder="Search..."
                                        class="os-13-r"
                                        background-color="white"
                                        v-model="mainSearch"
                                        clearable
                                        @click:clear="clearMainSearch"
                                    >
                                        <template v-slot:append>
                                            <v-icon
                                                color="#C5CEE0"
                                                class="ma-0"
                                            >
                                                {{ mdiMagnify }}
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-right pt-0"
                        >
                            <v-btn
                                class="no-uppercase rounded-lg os-13-sb mr-1"
                                color="#2E567A"
                                text
                                @click="clearFilters"
                            >
                                Clear Filters
                            </v-btn>
                            <!-- Search Button -->
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="filterTalent"
                            >
                                <span v-if="!$store.state.general.customBreakpoints.xxs">Filter Talent</span>
                                <v-icon
                                    class="ml-1"
                                >
                                    {{ mdiFilterPlusOutline }}
                                </v-icon>
                            </v-btn>
                            <!-- Search Button -->
                        </v-col>
                    </v-row>
                    <!-- Filters -->
                </v-col>

                <!-- No Talent -->
                <v-col
                    v-if="items.length === 0 && !isLoading"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    {{ noDataText }}
                </v-col>
                <!-- No Talent -->

                <!-- Talent -->
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    class="pa-lg-1 pa-xl-3"
                    v-for="(item, index) in items"
                    :key="index"
                    v-else
                >
                    <!-- Talent Card -->
                    <work-talent-general-landing-card-component
                        :item="item"
                        @readMore="readMore"
                    />
                    <!-- Talent Card -->
                </v-col>
                <!-- Talent -->

                <v-col
                    v-if="isLoading"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                    cols="12"
                >
                    Loading talent...
                    <br />
                    <br />
                    <v-progress-circular
                        indeterminate
                        color="#FFA858"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
    import { mdiMagnify, mdiFilterPlusOutline } from '@mdi/js'
    import { LookupIndustryController, LookupJobTitleController, LookupJobController, LookupLocationController, WorkTalentLandingController } from '@/logic/controller/index.controller.js'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import WorkTalentGeneralLandingCardComponent from '@/components/work/professionalService/general/WorkTalentGeneralLandingCardComponent.vue'

    export default {
        name: 'LandingSearchTalentPage',

        components: {
            GeneralGoogleAutocompleteAddressComponent,
            WorkTalentGeneralLandingCardComponent
        },

        watch: {
            // searchJobMode: {
            //     handler (value) {
            //         if (value) {
            //             if (value.id === 1) {
            //                 this.searchCountry = {
            //                     id: null
            //                 }
            //                 this.searchState = null
            //                 this.searchCity = null

            //                 this.address = {
            //                     address_line_1: null
            //                 }
            //             }
            //         }
            //     }
            // }
        },

        computed: {
            canEnterLocation () {
                // if (this.searchJobMode) {
                //     if (this.searchJobMode.id) {
                //         if (this.searchJobMode.id === 2) return true
                //         else return false
                //     } else return false
                // } else return false
                return true
            },

            locationPlaceholder () {
                // if (this.searchJobMode) {
                //     if (this.searchJobMode.id) {
                //         if (this.searchJobMode.id === 2) return 'Location'
                //         else return 'Select "On Location" mode'
                //     } else return 'Select "On Location" mode'
                // } else return 'Select "On Location" mode'
                return 'Location'
            },

            noDataText () {
                return 'No talent'
            }
        },

        data () {
            return {
                items: [],
                mdiMagnify,
                mdiFilterPlusOutline,
                lookupIndustryController: null,
                lookupJobTitleController: null,
                lookupJobController: null,
                lookupLocationController: null,
                workJobLandingController: null,
                jobModes: [],
                industries: [],
                jobTitles: [],
                searchIndustry: {
                    id: null
                },
                searchJobTitle: {
                    id: null
                },
                searchJobMode: {
                    id: null
                },
                searchCountry: {
                    id: null
                },
                searchState: null,
                searchCity: null,
                countries: [],
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                mainSearch: '',
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true
            }
        },

        beforeMount () {
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
            this.lookupJobController = new LookupJobController()
            this.lookupLocationController = new LookupLocationController()
            this.workTalentLandingController = new WorkTalentLandingController()
        },

        async mounted () {
            if (this.$router.currentRoute.query.search && this.$router.currentRoute.query.search.replace(/\s/g, '') !== '') this.mainSearch = this.$router.currentRoute.query.search

            this.getJobModes()
            this.getIndustries()
            this.getJobTitles()
            this.getCountries()
            await this.getTalent()

            var self = this
        
            const talentContainer = document.getElementById('talentContainer')
            talentContainer.addEventListener('scroll', async () => {
                if (talentContainer.offsetHeight + talentContainer.scrollTop + 500 >= talentContainer.scrollHeight) {
                    if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                        await this.getTalent()
                    }
                }  
            })
        },

        methods: {
            async getJobModes () {
                try {
                    this.jobModes = await this.lookupJobController.getJobModes()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(true, false, false, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobTitles () {
                try {
                    this.jobTitles = await this.lookupJobTitleController.get(false, false, true, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async filterTalent () {
                this.lastPageLoaded = 0
                this.items = []
                await this.getTalent()
            },

            async clearFilters () {
                this.lastPageLoaded = 0
                this.items = []
                this.searchIndustry = {
                    id: null
                }
                this.searchJobTitle = {
                    id: null
                }
                this.searchJobMode = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null
                this.mainSearch = ''
                this.address = {
                    address_line_1: null
                }
                await this.getTalent()
            },

            clearJobMode () {
                this.searchJobMode = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null

                this.address = {
                    address_line_1: null
                }
            },

            async getTalent () {
                try {
                    this.isLoading = true

                    let query = {}

                    let industryID = null
                    if (this.searchIndustry) {
                        if (this.searchIndustry.id) {
                            industryID = this.searchIndustry.id
                            query.industryID = industryID
                        }
                    }

                    let jobTitleID = null
                    if (this.searchJobTitle) {
                        if (this.searchJobTitle.id) {
                            jobTitleID = this.searchJobTitle.id
                            query.jobTitleID = jobTitleID
                        }
                    }

                    let modeID = null
                    // if (this.searchJobMode) {
                    //     if (this.searchJobMode.id) {
                    //         modeID = this.searchJobMode.id
                    //         query.modeID = modeID
                    //         if (modeID === 1) {
                    //             this.searchState = null
                    //             this.searchCity = null
                    //             this.searchCountry = { id: null }

                    //             this.address = {
                    //                 address_line_1: null
                    //             }
                    //         }
                    //     } else {
                    //         this.searchState = null
                    //         this.searchCity = null
                    //         this.searchCountry = { id: null }

                    //         this.address = {
                    //             address_line_1: null
                    //         }
                    //     }
                    // } else {
                    //     this.searchState = null
                    //     this.searchCity = null
                    //     this.searchCountry = { id: null }

                    //     this.address = {
                    //         address_line_1: null
                    //     }
                    // }

                    let countryID = null
                    if (this.searchCountry) {
                        if (this.searchCountry.id) {
                            countryID = this.searchCountry.id
                            query.countryID = countryID
                        }
                    }

                    if (this.searchState) query.state = this.searchState
                    if (this.searchCity) query.city = this.searchCity

                    // if (Object.keys(query).length > 0) {
                    //     let routeQuery = this.$route.query
                    //     if (Object.keys(routeQuery).length > 0) {
                    //         for(var prop in routeQuery) {
                    //             query[prop] = routeQuery[prop]
                    //         }
                    //     }
                    // } else {
                    //     query.view = this.$route.query.view
                    // }

                    // if (query != this.$route.query) this.$router.replace({ query })

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    let recordsToRetrieve = 10

                    const response = await this.workTalentLandingController.retrieve(this.lastPageLoaded, recordsToRetrieve, this.mainSearch, industryID, jobTitleID, null, modeID, countryID, this.searchState, this.searchCity)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.create_date) {
                                const removeTime = item.create_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.create_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                            }

                            this.items.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const talentContainer = document.getElementById('talentContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && talentContainer.offsetHeight + talentContainer.scrollTop === talentContainer.scrollHeight) await this.getTalent()
                    })
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            googlePlaceSelected (place) {
                if (place) {
                    Object.assign(this.address, place)

                    this.searchState = place.state
                    this.searchCity = place.city

                    const countryIndex = this.countries.findIndex(country => country.iso_2 === place.country.country_code)
                    if (countryIndex > -1) this.searchCountry.id = this.countries[countryIndex].id
                } else {
                    this.searchState = null
                    this.searchCity = null
                    this.searchCountry = { id: null }

                    this.address = {
                        job_id: null,
                        user_id: null,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            addressChanged (address) {
                this.address.address_line_1 = address
            },

            clearMainSearch () {
                this.mainSearch = ''
                if (this.$route.fullPath !== '/browse/talent') this.$router.replace('/browse/talent')
            },

            readMore () {
                this.$store.commit('setSignupOverlay', true)
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
